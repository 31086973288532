<template>
  <div id="naga-lp">
    <Header />
    <FormSection />
    <Testimonials />
    <AboutNaga />
    <Footer />
    <RiskWarning />
  </div>
</template>

<script>
export default {
  components: {
    Header: () => import("../../components/lps/naga/Header"),
    FormSection: () => import("../../components/lps/naga/FormSection"),
    Testimonials: () => import("../../components/lps/naga/Testimonials"),
    AboutNaga: () => import("../../components/lps/naga/AboutNaga"),
    Footer: () => import("../../components/lps/naga/Footer"),
    RiskWarning: () => import("../../components/lps/naga/RiskWarning"),
  },
};
</script>

<style></style>
